/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 125번째 로그에서는 쿠버네티스 책 신간, 젯브레인 Projector 출시, 윈도우 365에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "동양북스 신간 소개 & 이벤트"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://coupa.ng/b5UDa5"
  }, "클라우드 네이티브를 위한 쿠버네티스 실전 프로젝트 - 동양북스")), "\n"), "\n", React.createElement(_components.h3, null, "젯브레인 Projector 출시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.jetbrains.com/ko/blog/2021/03/24/projector-is-out/"
  }, "Projector로 IDE에 원격으로 액세스 하세요 | JetBrains News")), "\n"), "\n", React.createElement(_components.h3, null, "윈도우 365"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://byline.network/2021/07/16-148/"
  }, "윈도우 365, 구독형 OS의 서막 - Byline Network")), "\n"), "\n", React.createElement(_components.h3, null, "AWS egress 요금 비판"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gigazine.net/news/20210726-cloudflare-aws-egress-fees/"
  }, "일본어 - AWS 전송 요금은 엄청난 정도로 고액이라고 Cloudflare 비판 (AWSの転送料金は法外なほどに高額だとCloudflareが批判) - GIGAZINE")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
